import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { AdProps, AdViewProps, DisplayAdProps, AdFlexModelResult } from "./typings";
import { classNames } from "@egds/react-core/utils";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { ViewExtraLarge, ViewLarge, Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { PrivacyTrackingState } from "bernie-context";
import { AdBanner } from "@shared-ui/retail-sponsored-content-ad-banner";

const CT_SIZES = ["CT1", "CT2", "CT3", "CT4"];
const CM_SIZES = ["CM1", "CM2", "CM3", "CM4"];

const AdModule = (props: AdProps) => {
  const { context, advertising, flexModuleModelStore, templateComponent } = props;

  /* istanbul ignore next */
  advertising?.includeAd?.();

  /* istanbul ignore next */
  if (
    !advertising ||
    !templateComponent ||
    !flexModuleModelStore ||
    !context ||
    context?.privacyTrackingState === PrivacyTrackingState.CAN_NOT_TRACK
  ) {
    return null;
  }

  const {
    metadata: { id },
  } = templateComponent;

  const model = flexModuleModelStore.getModel(id) as AdFlexModelResult | null;

  if (!model) {
    return null;
  }

  return (
    <AdView data-testid="ad-component" templateComponent={templateComponent} model={model} advertising={advertising} />
  );
};

const RenderMesoDisplayAdExperinmentComponent = ({
  adSlotIdOverride,
  uci,
}: {
  adSlotIdOverride?: string;
  uci: string;
}) => {
  const refetchTrigger = React.useRef<HTMLDivElement>(null);

  return <AdBanner uci={adSlotIdOverride || uci} displayAdRef={refetchTrigger} />;
};

const DisplayAd = (props: DisplayAdProps) => {
  const { templateComponent, model, uci, advertising } = props;

  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;

  const adSize = uci.includes("CT") ? CT_SIZES : CM_SIZES;
  const overrideMarginTop = model.marginTopOverride === "sizing__three";
  const adMarginSpacing = overrideMarginTop ? "three" : "six";
  const hasNoVerticalSpacing = model.verticalSpacing === SpacingSize.NONE;
  const verticalSpacingDesktop = hasNoVerticalSpacing ? "unset" : adMarginSpacing;
  const verticalSpacingMobile = hasNoVerticalSpacing ? "unset" : "six";
  const displayAdClassNames = classNames("Ad", {
    "background-color-white": model.backgroundWhite,
  });
  const fmId = getFmId(templateComponent);

  return (
    <UitkSpacing margin={{ small: { blockstart: verticalSpacingMobile }, block: verticalSpacingDesktop }}>
      <div className={displayAdClassNames} id={id} data-fm={fmId} data-fm-title-id={fmTitleId} data-testid="display-ad">
        {adSize.map((uci) => (
          <UitkLayoutFlex justifyContent="center" key={uci}>
            <UitkLayoutFlexItem>
              <div>
                <RenderMesoDisplayAdExperinmentComponent adSlotIdOverride={advertising.adSlotIdOverride} uci={uci} />
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        ))}
      </div>
    </UitkSpacing>
  );
};

const AdView = observer((props: AdViewProps) => {
  const { model, templateComponent } = props;

  return (
    <Viewport>
      <ViewExtraLarge>
        <DisplayAd
          templateComponent={templateComponent}
          model={model}
          advertising={props.advertising}
          uci={model.extraLargeAd || CM_SIZES[0]}
        />
      </ViewExtraLarge>
      <ViewLarge>
        <DisplayAd
          templateComponent={templateComponent}
          model={model}
          advertising={props.advertising}
          uci={model.largeAd || CM_SIZES[1]}
        />
      </ViewLarge>
      <ViewMedium>
        <DisplayAd
          templateComponent={templateComponent}
          model={model}
          advertising={props.advertising}
          uci={model.mediumAd || CM_SIZES[2]}
        />
      </ViewMedium>
      <ViewSmall>
        <DisplayAd
          templateComponent={templateComponent}
          model={model}
          advertising={props.advertising}
          uci={model.smallAd || CM_SIZES[3]}
        />
      </ViewSmall>
    </Viewport>
  );
});

(AdView as React.SFC).displayName = "AdView";

export const Ad = withStores("advertising", "flexModuleModelStore")(observer(AdModule));

export default Ad;
